import React from 'react'
import LayoutComponent from '../../../components/documentation-layout/Layout'

const CustomizeGeneral = () => {
  return (
    <LayoutComponent>
      <h3>
        Customize General Configurations
      </h3>
      <ol>
        <li>Click either Environments, Labels, Iterations, Modules or Priorities.</li>
        <li>Enter a name.</li>
      </ol>
      <div className='testimonials'>
        <h5>Tip</h5>
        <p>
          You can enter a description if you want to add Environment, Label or Iteration. This is optional.
        </p>
      </div>
      <ol start="3">
        <li>Click Add.</li>
        <li>Your addition appears in the list below.</li>
      </ol>

    </LayoutComponent>
  )
}

export default CustomizeGeneral
